<script>
    import { createEventDispatcher } from "svelte";

    const dispatch = createEventDispatcher();

    let hoverEdit = false;
    let hoverRemove = false;
</script>

<div class="flex justify-end mb-2">
    <button on:click={() => dispatch("edit")}>
        <svg
            on:mouseenter={() => (hoverEdit = true)}
            on:mouseleave={() => (hoverEdit = false)}
            class="mr-2 {hoverEdit ? 'highlighted' : 'default'}"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="15"
            height="15"
            viewBox="0 0 20 20"
        >
            <path
                d="M19.104 0.896c-0.562-0.562-1.309-0.871-2.104-0.871s-1.542
        0.309-2.104 0.871l-12.75 12.75c-0.052 0.052-0.091 0.114-0.116
        0.183l-2 5.5c-0.066 0.183-0.021 0.387 0.116 0.524 0.095 0.095
        0.223 0.146 0.354 0.146 0.057 0 0.115-0.010
        0.171-0.030l5.5-2c0.069-0.025 0.131-0.065
        0.183-0.116l12.75-12.75c0.562-0.562 0.871-1.309
        0.871-2.104s-0.309-1.542-0.871-2.104zM5.725 17.068l-4.389 1.596
        1.596-4.389 11.068-11.068 2.793 2.793-11.068 11.068zM18.396
        4.396l-0.896 0.896-2.793-2.793 0.896-0.896c0.373-0.373
        0.869-0.578 1.396-0.578s1.023 0.205 1.396 0.578c0.373 0.373
        0.578 0.869 0.578 1.396s-0.205 1.023-0.578 1.396z"
            />
        </svg>
    </button>
    <button on:click={() => dispatch("remove")}>
        <svg
            on:mouseenter={() => (hoverRemove = true)}
            on:mouseleave={() => (hoverRemove = false)}
            class={hoverRemove ? "highlighted" : "default"}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="14"
            height="14"
            viewBox="0 0 20 20"
        >
            <path
                d="m 11.081457,10.008274 8.638211,-8.6382119 c
                0.298344,-0.2983443 0.298344,-0.78334475 0,-1.08168891
                -0.298344,-0.29834415 -0.783344,-0.29834415 -1.081689,0 L
                9.9997681,8.9265841 1.3615571,0.28837319 c
                -0.2983435,-0.29834415 -0.78334399,-0.29834415 -1.08168862,0
                -0.29834464,0.29834416 -0.29834464,0.78334461 0,1.08168891 L
                8.9180795,10.008274 0.27986848,18.646483 c -0.29834464,0.298344
                -0.29834464,0.783345 0,1.08169 0.14993672,0.149936
                0.34577271,0.223375 0.54160991,0.223375 0.19583601,0
                0.39167201,-0.07496 0.54160871,-0.223375 l 8.6382109,-8.638211
                8.638211,8.638211 c 0.149937,0.149936 0.345774,0.223375
                0.54161,0.223375 0.195836,0 0.391672,-0.07496 0.541609,-0.223375
                0.298344,-0.298345 0.298344,-0.783346 0,-1.08169 l
                -8.638211,-8.638209 z"
            />
        </svg>
    </button>
</div>

<style>
    .default {
        fill: var(--color-neutral-medium);
    }

    .highlighted {
        fill: var(--color-neutral);
        cursor: pointer;
    }
</style>
