import { fetcher } from "@edenflowers/common";
import config from "../config";
import utils from "../utils";
function makeUrl({ type, category }, id) {
    const params = new URLSearchParams();
    if (type && type.length > 0) {
        params.append("type", type.join(","));
    }
    if (category && category.length > 0) {
        params.append("category", category.join(","));
    }
    const paramsStr = params.toString();
    return `${config.serverUrl}/product${id ? "/" + id : ""}${paramsStr ? "?" + paramsStr : ""}`;
}
export async function getProducts({ type, category }, id) {
    return fetcher({
        url: makeUrl({ type, category }, id === null || id === void 0 ? void 0 : id.join(",")),
        method: "GET",
        callback: (err) => utils.sendErrorToSentry(err),
    });
}
