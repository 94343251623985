<script lang="ts">
    import { getContext } from "svelte";
    import { _ } from "svelte-intl";
    import { cardStore, productStore } from "../../../stores";
    import CardPicker from "./CardPicker.svelte";
    import Banner from "../../../components/Banner.svelte";
    import SelectedCard from "./SelectedCard.svelte";
    import config from "../../../config";
    import { fullScreenOnMobile } from "../../../components/modalOptions";

    export let text = "";
    export let errors: { [k: string]: boolean } = {};
    export let isError = false;
    $: chars = text.length || 0;
    let maxLength = config.messageLength.small;

    let cards = $productStore.filter((p) => {
        if (p.type !== "card") return false;
        return true;
    });
    $: selectedCard = cards.find((c) => c.id === $cardStore);

    // If customer has written a message intended for a large card
    // and then switches to a smaller card where the character count
    // exceeds the selected cards character limit, show error message.
    let bannerMessage = "";
    $: (() => {
        if (chars > maxLength) {
            isError = true;
            bannerMessage = $_("message_exceeds_card_length");
        } else {
            isError = false;
            bannerMessage = "";
        }
    })();

    // Update permitted text length based on selected card size
    $: (() => {
        switch (selectedCard?.size) {
            case "large":
                maxLength = config.messageLength.large;
                break;
            case "medium":
                maxLength = config.messageLength.medium;
                break;
            case "small":
                if (selectedCard?.cost <= 100) {
                    maxLength = config.messageLength.xsmall;
                } else {
                    maxLength = config.messageLength.small;
                }
        }
    })();

    const { open } = getContext("simple-modal");
    function showCardPickerModal() {
        open(
            CardPicker,
            {
                cards,
            },
            fullScreenOnMobile(window.innerWidth)
        );
    }
</script>

<div class="mb-3">
    <div class="mb-def">
        <h1>{$_("add_message")}</h1>
        <span class="text-sm" for={"_addmessage"}>{$_("add_message_description")}</span>

        {#if bannerMessage}
            <Banner>
                <div class="text-sm">{bannerMessage}</div>
            </Banner>
        {/if}
    </div>

    <div class="flex border rounded overflow-hidden {errors['message'] ? 'border-warning' : ''}">
        <div class="message relative w-full">
            <textarea
                id={"_addmessage"}
                class="w-full h-full text-sm resize-none overflow-y-scroll
                        no-scrollbar p-3"
                bind:value={text}
                maxlength={maxLength}
            />
            <span
                class="absolute bottom-0 right-0 p-3 text-xs uppercase font-sans
                        font-medium {chars >=
                maxLength
                    ? 'text-neutral'
                    : 'text-neutral-medium'}"
            >
                {chars}
                /
                {maxLength}
            </span>
        </div>

        {#if selectedCard}
            <button on:click={showCardPickerModal} class="cursor-pointer mt-1 mr-1 mb-1">
                <SelectedCard card={selectedCard} />
            </button>
        {/if}
    </div>
</div>

<style>
    .message {
        flex: 1 0 70%;
    }
</style>
