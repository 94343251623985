import { fetcher } from "@edenflowers/common";
import config from "../config";
import utils from "../utils";
export async function checkPhoneNumber(query) {
    const res = await fetcher({
        method: "GET",
        url: `${config.serverUrl}/validate/phonenumber/${encodeURIComponent(query)}`,
        callback: (err) => utils.sendErrorToSentry(err),
    });
    return res;
}
