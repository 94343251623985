<script lang="ts">
    import { _ } from "svelte-intl";
    import Spinner from "../../../components/Spinner.svelte";
    import type { Item } from "../../../types";
    import CartItem from "./CartItem.svelte";

    export let items: Item[] = [];
</script>

<h1 class="mb-def">{$_("your_cart")}</h1>

{#if items.length > 0}
    <div class="flex flex-row mb-def">
        {#each items as item (item.productId)}
            <CartItem item={item} />
        {/each}
    </div>
{:else}
    <Spinner margin={1} color="var(--color-secondary)" />
{/if}
