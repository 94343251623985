<script>
    import Logo from "../svg/Logo.svelte";
    import { navigate } from "svelte-navigator";
</script>

<header>
    <div class="my-8 flex justify-center">
        <button on:click={() => navigate("https://edenflowers.fi")} class="logo">
            <Logo />
        </button>
    </div>
</header>
