<script>
    export let color = "white";
    export let margin = 0;
    $: style = `--color-spinner: ${color}; margin-top: ${margin}rem; margin-bottom: ${margin}rem`;
</script>

<div style={style} role="alertdialog" aria-busy="true" aria-live="assertive">
    <svg class="spinner" viewBox="0 0 50 50">
        <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5" />
    </svg>
</div>

<style>
    .spinner {
        -webkit-animation: rotate 2s linear infinite;
        animation: rotate 2s linear infinite;
        width: 1.5rem;
        height: 1.5rem;
        margin: 0 auto;
    }

    .spinner .path {
        stroke: var(--color-spinner);
        stroke-linecap: round;
        -webkit-animation: dash 1.5s ease-in-out infinite;
        animation: dash 1.5s ease-in-out infinite;
    }

    @-webkit-keyframes rotate {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes rotate {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @-webkit-keyframes dash {
        0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
        }
        100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
        }
    }

    @keyframes dash {
        0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
        }
        100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
        }
    }
</style>
