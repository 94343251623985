<script>
    // @ts-check
    import { _ } from "svelte-intl";
    import Asset37 from "../svg/flowers/Asset37.svelte";
</script>

<div class="wrapper flex flex-col">
    <h1 class="mb-4 text-center">{$_("page_not_found")}</h1>

    <Asset37 />
</div>
