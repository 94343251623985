import { fetcher } from "@edenflowers/common";
import config from "../config";
import utils from "../utils";
export async function lookupAddress(query, lang) {
    const url = `${config.serverUrl}/delivery/address/${encodeURIComponent(query)}?lang=${lang}`;
    const res = await fetcher({
        method: "GET",
        url,
        callback: (err) => utils.sendErrorToSentry(err),
    });
    return res;
}
