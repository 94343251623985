import { fetcher } from "@edenflowers/common";
import config from "../config";
import utils from "../utils";
export async function initCardPayment(req) {
    const res = await fetcher({
        url: `${config.serverUrl}/payment`,
        method: "POST",
        reqObject: req,
        callback: (err) => utils.sendErrorToSentry(err),
    });
    return res;
}
