<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import Card from "./Card.svelte";
    import type { ProductTypes } from "@edenflowers/server";
    import CloseModal from "../../../components/CloseModal.svelte";

    const dispatch = createEventDispatcher();

    export let cards: ProductTypes.GetProductResBody = [];
    export let selected: string = "";
    export let heading = "";
    export let subheading = "";
    export let showCloseModalButton = false;

    function handleClick(id: string) {
        dispatch("click", id);
    }
</script>

<div class="mb-4 leading-none flex flex-row justify-between">
    <div>
        <h1>{heading}</h1>
        <span class="text-lg text-primary">{subheading}</span>
    </div>

    {#if showCloseModalButton}
        <div class="h-auto">
            <CloseModal />
        </div>
    {/if}
</div>

<div class="mb-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-2 gap-y-5">
    {#each cards as card}
        <svelte:component
            this={Card}
            card={card}
            isSelected={card.id === selected}
            on:click={() => handleClick(card.id)}
        />
    {/each}
</div>
