<script>
    // @ts-check
    import { fly } from "svelte/transition";

    const transition = {
        y: -4,
        duration: 250,
    };
</script>

<div in:fly={transition} class="border border-transparent rounded p-3 bg-neutral-light">
    <slot />
</div>
