<script>
    // @ts-check
    import { createEventDispatcher } from "svelte";
    import Spinner from "../components/Spinner.svelte";

    const dispatch = createEventDispatcher();

    export let isProcessing = false;
    export let style = "";

    function handleClick() {
        if (!isProcessing) {
            dispatch("click");
        }
    }
</script>

<button
    disabled={isProcessing}
    class="button {isProcessing ? 'cursor-default' : 'cursor-pointer'}
        {style
        ? style
        : 'self-center w-full p-3 font-bold text-white bg-brand rounded shadow-sm'}"
    on:click={handleClick}
>
    {#if isProcessing}
        <Spinner />
    {:else}
        <slot />
    {/if}
</button>
