<script>
    import { getContext } from "svelte";
    import Close from "../svg/Close.svelte";

    const { close } = getContext("simple-modal");
</script>

<div on:click={close} class="cursor-pointer">
    <Close fill="var(--color-neutral)" height="24" width="24" />
</div>
