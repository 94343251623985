<script lang="ts">
    import type { Delivery } from "../../../types";

    export let delivery: Delivery | undefined = undefined;

    const { firstName, lastName, address } = delivery as Delivery;
</script>

<div>{firstName} {lastName}</div>
<div>{address.street} {address.houseNumber}</div>
<div>{address.postalCode} {address.city}</div>
