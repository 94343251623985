import { localStore } from "../lib/localStore";
import { store } from "../lib/store";
export const deliveriesStore = localStore("deliveries", []);
export const paymentStore = localStore("paymentStore", null);
export const hashStore = localStore("hash", "");
export const stripeStore = localStore("stripe", "");
export const orderStore = localStore("orderId", "");
export const reset = () => {
    deliveriesStore.reset();
    paymentStore.reset();
    hashStore.reset();
    stripeStore.reset();
    orderStore.reset();
};
export const cardStore = store("");
export const extrasStore = store([]);
export const stateStore = store({
    stripeLoaded: false,
    sentryLoaded: false,
});
export const productStore = store([]);
export const calendarStore = store(undefined);
