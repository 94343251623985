<script>
    export let fill = "var(--color-neutral)";
    export let height = "16";
    export let width = "16";
</script>

<svg fill={fill} viewBox="0 0 1024 1024" height={height} width={width}>
    <path
        d="M548.203 537.6l289.099-289.098c9.998-9.998
                        9.998-26.206 0-36.205-9.997-9.997-26.206-9.997-36.203
                        0l-289.099
                        289.099-289.098-289.099c-9.998-9.997-26.206-9.997-36.205
                        0-9.997 9.998-9.997 26.206 0 36.205l289.099
                        289.098-289.099 289.099c-9.997 9.997-9.997 26.206 0
                        36.203 5 4.998 11.55 7.498 18.102 7.498s13.102-2.499
                        18.102-7.499l289.098-289.098 289.099 289.099c4.998 4.998
                        11.549 7.498 18.101 7.498s13.102-2.499
                        18.101-7.499c9.998-9.997 9.998-26.206
                        0-36.203l-289.098-289.098z"
    />
</svg>
