<script lang="ts">
    import { createEventDispatcher, onMount } from "svelte";

    import Label from "./Label.svelte";

    const dispatch = createEventDispatcher();

    export let value = "";
    export let name = "";
    export let label = "";
    export let readonly = false;
    export let errors: { [key: string]: boolean } = {};
    export let placeholder = "";
    export let inputMode = "text";

    let hasFocus = false;

    function onFocus() {
        hasFocus = true;
    }

    function onBlur() {
        hasFocus = false;
        dispatch("blur");
    }

    function makeId() {
        return "_" + Math.random().toString(36).substr(2, 9);
    }

    let id: string;
    onMount(() => {
        id = makeId();
    });
</script>

<Label id={id} name={label.trim().replace(/\s/g, "-").toLowerCase()} text={label} />
<slot name="above-input" />
<input
    id={id}
    readonly={readonly}
    name={name}
    placeholder={placeholder}
    on:focus={onFocus}
    on:blur={onBlur}
    bind:value
    inputmode={inputMode}
    type="text"
    class="input input__size input__style {errors[name] ? 'border-warning' : ''}"
/>
<slot name="below-input" />
