import { config } from "@edenflowers/common";
import { DateTime } from "luxon";
export function isFuture(a, b) {
    return a.startOf("day") > b.startOf("day");
}
export function isPast(a, b) {
    return a.startOf("day") < b.startOf("day");
}
export function datesAreEqual(a, b) {
    return a.startOf("day").equals(b.startOf("day"));
}
export function getCalendarEntry(date, calendarDates) {
    for (let i = 0; i < calendarDates.length; i++) {
        if (datesAreEqual(date, DateTime.fromJSDate(new Date(calendarDates[i].date)))) {
            return calendarDates[i];
        }
    }
}
export function getDeliveryWindowStart(date, calendarDates) {
    const entry = getCalendarEntry(date, calendarDates);
    if (entry) {
        return DateTime.fromISO(entry.deliveryWindowStart);
    }
    return date.set({ hour: config.delivery.deliverFromHour });
}
