<script>
    import { _ } from "svelte-intl";
    import Asset37 from "../../../svg/flowers/Asset37.svelte";
</script>

<div class="wrapper flex flex-col">
    <div class="flex flex-col">
        <h1 class="mb-3">{$_("order_received_title")}</h1>
        <p>{$_("order_received_body")}</p>
    </div>

    <Asset37 />
</div>
