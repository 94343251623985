<script lang="ts">
    import { createEventDispatcher, getContext } from "svelte";
    import { _, locale } from "svelte-intl";
    import { deliveriesStore } from "../../../stores";
    import DeliveryAddress from "./DeliveryAddress.svelte";
    import LineItem from "./LineItem.svelte";
    import Spinner from "../../../components/Spinner.svelte";
    import type { CartTypes } from "@edenflowers/server";
    import Heading from "./Heading.svelte";
    import { DateTime } from "luxon";
    import { config } from "@edenflowers/common";
    import StorePickupModal from "../../../components/StorePickupModal.svelte";
    import { fullScreenOnMobile } from "../../../components/modalOptions";

    const dispatch = createEventDispatcher();

    export let cart: CartTypes.CalculateCartResBody | undefined;

    function editOrder(hash: string) {
        dispatch("editOrder", hash);
    }

    function removeOrder(hash: string) {
        dispatch("removeOrder", hash);
    }

    function posToNeg(n: number) {
        return -Math.abs(n);
    }

    const { open } = getContext("simple-modal");
    function showPickupModal() {
        open(StorePickupModal, {}, fullScreenOnMobile(window.innerWidth));
    }

    $: homeDelivery = $deliveriesStore[0].deliveryMethod === "homeDelivery";
</script>

<div class="flex flex-row justify-between items-center">
    <h1 class="mb-def">{$_("order_summary")}</h1>

    {#if cart}
        <svelte:component
            this={Heading}
            on:edit={() => editOrder(cart.deliveries[0].hash)}
            on:remove={() => removeOrder(cart.deliveries[0].hash)}
        />
    {/if}
</div>

{#if cart}
    {#each cart.deliveries as delivery}
        <div class="mb-def">
            <div class="flex flex-row justify-between mb-6">
                <div class="flex justify-between">
                    <div>
                        <h6 class="label">
                            {#if homeDelivery}
                                {$_("delivery_address")}
                            {:else}
                                {$_("pickup_location")}
                            {/if}
                        </h6>

                        {#if homeDelivery}
                            <DeliveryAddress
                                delivery={$deliveriesStore.find((d) => d.hash === delivery.hash)}
                            />
                        {:else}
                            <div class="flex flex-col items-start">
                                <span>
                                    {config.delivery.minimossenAddress.label}
                                </span>
                                <button
                                    on:click={showPickupModal}
                                    class="font-bold text-brand text-sm"
                                >
                                    {$_("more_info")}
                                </button>
                            </div>
                        {/if}
                    </div>
                </div>

                <div class="text-right">
                    <h6 class="label">
                        {#if homeDelivery}
                            {$_("delivery_date")}
                        {:else}
                            {$_("pickup_date_time")}
                        {/if}
                    </h6>
                    <div>
                        {DateTime.fromISO($deliveriesStore[0].date)
                            .setLocale("fi-FI")
                            .toLocaleString(DateTime.DATE_SHORT)}
                    </div>
                </div>
            </div>

            <h6 class="label">{$_("items")}</h6>
            {#each delivery.items as item}
                <LineItem
                    text={item.translations.find((t) => t.language === $locale).name}
                    isDiscounted={item.itemDiscounted}
                    total={item.itemCost}
                    totalWithDiscount={item.itemCostWithDiscount}
                />
            {/each}

            {#if homeDelivery}
                <LineItem
                    text={$_("delivery")}
                    isDiscounted={delivery.deliveryDiscounted}
                    total={delivery.deliveryCost}
                    totalWithDiscount={delivery.deliveryCostWithDiscount}
                    useFree={true}
                />
            {/if}

            <div class="border-t border-dashed my-1" />

            <!-- Note, the following section needs refactored if the site should accept multiple deliveries -->
            {#if cart?.orderDiscounted}
                <LineItem text={$_("promo")} total={posToNeg(cart?.promoDeductible)} />
            {/if}

            {#if cart?.giftCardApplied}
                <LineItem text={$_("gift_card")} total={posToNeg(cart?.giftCardDeductible)} />
            {/if}

            <LineItem text={$_("total")} bold={true} total={cart?.payable} />
        </div>
    {/each}
{:else}
    <Spinner margin={3} color={"var(--color-secondary)"} />
{/if}
