<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import { locale, _ } from "svelte-intl";
    import Banner from "../../../components/Banner.svelte";
    import BinaryInput from "../../../components/BinaryInput.svelte";
    import api from "../../../api";
    import Tick from "../../../svg/Tick.svelte";
    import WarnTriangle from "../../../svg/WarnTriangle.svelte";
    import type { GiftCardCheckRes } from "@edenflowers/server/dist/managers/giftcard";
    import utils from "../../../utils";

    const dispatch = createEventDispatcher();

    export let query = "";
    export let applied = false;
    export let remainingBalance: number;

    let text = "";
    let giftCard: GiftCardCheckRes | undefined;

    $: if (giftCard) {
        text = "";
        const { status } = giftCard;

        if (status === "INVALID") {
            text = $_("gift_card_invalid");
        } else if (status === "BALANCE_ZERO") {
            text = $_("gift_card_balance_zero", { amount: utils.localise.currency(0, $locale) });
        } else if (status === "EXPIRED") {
            text = $_("gift_card_expired");
        } else if (status === "VALID") {
            text = $_("gift_card_valid", {
                amount: utils.localise.currency(remainingBalance, $locale),
            });
        }
    }

    async function apply() {
        const checkGiftCardRes = await api.checkGiftCard(query);
        if (checkGiftCardRes.err) {
            // TODO: handle error
            console.log(checkGiftCardRes);
            return;
        }

        giftCard = checkGiftCardRes.val.data;
        dispatch("apply");
    }
</script>

<BinaryInput
    inputVisible={true}
    labelText={$_("gift_card")}
    bind:applied
    bind:query
    on:reset
    on:apply={apply}
>
    <Banner>
        <div class="flex leading-none items-center">
            <div class="h-4 w-4 flex-shrink-0 mr-2">
                {#if giftCard && giftCard.status === "VALID"}
                    <Tick />
                {:else}
                    <WarnTriangle />
                {/if}
            </div>
            <p class="text-sm">{text}</p>
        </div>
    </Banner>
</BinaryInput>
