import { fade, fly } from "svelte/transition";
export const standard = {
    closeButton: false,
    closeOnEsc: true,
    closeOnOuterClick: true,
    transitionBg: fade,
    transitionBgProps: {
        duration: 180,
    },
    transitionWindow: fly,
    transitionWindowProps: {
        y: 30,
        duration: 300,
    },
    styleBg: {
        background: "rgba(0,0,0,0.32)",
        "backdrop-filter": "blur(0.18rem)",
    },
    styleWindowWrap: {},
    styleWindow: { "border-radius": "0" },
    styleContent: {
        background: "var(--color-background)",
    },
};
export const fullScreenOnMobile = (n = window.innerWidth) => {
    const mobile = n < 768;
    function getHeight() {
        return mobile ? "100%" : "auto";
    }
    return Object.assign(Object.assign({}, standard), { styleBg: Object.assign(Object.assign({}, standard.styleBg), { background: "rgba(0,0,0,0.32)", "backdrop-filter": "none" }), styleWindowWrap: Object.assign(Object.assign({}, standard.styleWindow), { margin: "0", height: getHeight() }), styleWindow: Object.assign(Object.assign({}, standard.styleWindow), { margin: "0 auto", height: getHeight() }), styleContent: Object.assign(Object.assign({}, standard.styleContent), { 
            // Ok, this sucks, but it will do for now
            "max-height": mobile ? "100vh" : "calc(100vh - 4rem)", height: getHeight() }) });
};
