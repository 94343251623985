<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import Item from "./Item.svelte";
    import type { Item as ItemType } from "./Item";

    export let items: ItemType[] = [];
    export let hoverItemIndex = 0;

    const dispatch = createEventDispatcher();

    function handleSelect(item: ItemType) {
        dispatch("itemSelected", item);
    }

    function closeList() {
        dispatch("closeList");
    }

    function handleHover(i: number) {
        hoverItemIndex = i;
    }

    function handleClick(item: ItemType) {
        handleSelect(item);
    }

    function isItemHover(hoverItemIndex: number, itemIndex: number, items: ItemType[]) {
        return hoverItemIndex === itemIndex || items.length === 1;
    }

    function updateHoverItem(n: number) {
        if (n > 0 && hoverItemIndex === items.length - 1) {
            hoverItemIndex = 0;
        } else if (n < 0 && hoverItemIndex === 0) {
            hoverItemIndex = items.length - 1;
        } else {
            hoverItemIndex += n;
        }
    }

    function handleKeyDown(
        event: KeyboardEvent & {
            currentTarget: EventTarget & Window;
        }
    ) {
        if (items.length > 0) {
            switch (event.key) {
                case "ArrowDown":
                    event.preventDefault();
                    items.length && updateHoverItem(1);
                    break;
                case "ArrowUp":
                    event.preventDefault();
                    items.length && updateHoverItem(-1);
                    break;
                case "Enter":
                    event.preventDefault();
                    if (items.length === 0) break;
                    handleSelect(items[hoverItemIndex]);
                    closeList();
                    break;
                case "Escape":
                    event.preventDefault();
                    closeList();
                    break;
                default:
                    break;
            }
        }
    }
</script>

<svelte:window on:keydown={handleKeyDown} />

{#if items.length > 0}
    <div class="shadow-lg rounded border max-h-1/4 overflow-y-auto bg-white">
        {#each items as item, itemIndex}
            <div on:mouseover={() => handleHover(itemIndex)} on:click={() => handleClick(item)}>
                <Item item={item} isHover={isItemHover(hoverItemIndex, itemIndex, items)} />
            </div>
        {/each}
    </div>
{/if}
