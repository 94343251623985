import config from "../config";
import { fetcher } from "@edenflowers/common";
import utils from "../utils";
export async function calculateCart(calculateCartReqBody) {
    const res = await fetcher({
        method: "POST",
        url: `${config.serverUrl}/cart/calculate`,
        reqObject: calculateCartReqBody,
        callback: (err) => utils.sendErrorToSentry(err),
    });
    return res;
}
