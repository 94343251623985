<script lang="ts">
    import LanguagePicker from "../components/LanguagePicker.svelte";
    import { _ } from "svelte-intl";
    import config from "../config";
</script>

<footer class="footer">
    <div class="mx-8 my-16">
        <div class="max-w-lg flex flex-row flex-wrap justify-between m-auto flex-y-space-2">
            <div class="text-sm mr-8">
                <span class="label">{$_("contact")}</span>

                <div class="mb-4">
                    <div>{config.telephone}</div>
                    <div>{config.email}</div>
                    <div>{$_("minimossen_address")}</div>
                </div>

                <div class="text-sm">{$_("ytunnus")} {config.ytunnus}</div>
            </div>

            <div class="mb-def">
                <span class="label" for={"_languagepicker"}>{$_("language")}</span>

                <div class="flex-grow-0">
                    <LanguagePicker id={"_languagepicker"} />
                </div>
            </div>
        </div>
    </div>
</footer>

<style>
    .footer {
        flex-shrink: 0;
        background-color: var(--color-footer);
        min-height: 20vh;
    }

    .flex-y-space-2 > * {
        @apply mb-8;
    }
</style>
