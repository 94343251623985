<script lang="ts">
    import { _ } from "svelte-intl";
    import type { Delivery } from "../../../types";

    export let selected: Delivery["deliveryMethod"] = "storePickup";

    function handleSelect(method: "homeDelivery" | "storePickup") {
        if (selected !== method) {
            selected = method;
        }
    }
</script>

<div class="flex flex-row justify-start">
    <button
        on:click={() => handleSelect("storePickup")}
        class="button item defaults {selected === 'storePickup'
            ? 'bg-primary text-white border-primary font-bold'
            : 'bg-white text-neutral'}
            border-l border-r rounded-l"
    >
        {$_("store_pickup")}
    </button>
    <button
        on:click={() => handleSelect("homeDelivery")}
        class="button item {selected === 'homeDelivery'
            ? 'bg-primary text-white border-primary font-bold'
            : 'bg-white text-neutral'} border-r
            rounded-r"
    >
        {$_("home_delivery")}
    </button>
</div>

<style>
    .item {
        border-top-width: 1px;
        border-bottom-width: 1px;
        padding: 0.75rem;
        text-align: center;
        cursor: pointer;
        user-select: none;
    }
</style>
