export default {
    serverUrl: process.env.SERVER_URL,
    deliverFromHour: 11,
    orderEndHour: 14,
    messageLength: {
        xsmall: 30,
        small: 60,
        medium: 120,
        large: 250,
    },
    hereOrigin: "63.124291,21.599897",
    telephone: "040 2209494",
    email: "info@edenflowers.fi",
    ytunnus: "2944459-6",
};
