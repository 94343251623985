<script lang="ts">
    import { locale } from "svelte-intl";
    import api from "../api";
    import Select from "../components/select/Select.svelte";
    import type { Item } from "../components/select/Item";

    export let errors: { [k: string]: boolean } = { address: false };
    export let placeholder = "";
    export let isSelected = false;
    export let text = "";

    let items: Item[] = [];
    let isError: boolean;
    $: isError = errors["address"];

    let isLoading = false;
    let interval = 380;
    let timer: number = 0;

    function limiter<T>({ value, callback }: { value?: T; callback: (value?: T) => void }) {
        clearTimeout(timer);

        if (value) {
            // Another solution than adding <never> or <any>?
            timer = <never>setTimeout(() => callback(value), interval);
        }
    }

    async function search(event: { detail: string }) {
        const query = event.detail;

        isLoading = true;

        if (query.length > 0) {
            limiter({
                value: query,
                callback: async (value) => {
                    // Clear list
                    items = [];

                    const res = await api.lookupAddress(query, $locale);

                    if (res.err) {
                        // TODO: handle error
                        console.error(res);
                        isLoading = false;
                        return;
                    }

                    // @ts-ignore
                    items = res?.val?.data || [];

                    isLoading = false;
                },
            });
        }
    }
</script>

<Select
    placeholder={placeholder}
    isError={isError}
    items={items}
    isLoading={isLoading}
    bind:text
    bind:isSelected
    on:textEntered={search}
    on:itemSelected
    on:itemCleared
/>
