<script lang="ts">
    import type { PaymentMethod } from "@edenflowers/server";
    import { _ } from "svelte-intl";

    export let selected: PaymentMethod;

    function handleSelect(value: PaymentMethod) {
        if (selected !== value) {
            selected = value;
        }
    }
</script>

<div class="flex flex-row justify-start">
    <button
        on:click={() => handleSelect("invoice")}
        class="button item defaults {selected === 'invoice'
            ? 'bg-primary text-white border-primary font-bold'
            : 'bg-white text-neutral'}
            border-l border-r rounded-l"
    >
        {$_("invoice")}
    </button>
    <button
        on:click={() => handleSelect("card")}
        class="button item {selected === 'card'
            ? 'bg-primary text-white border-primary font-bold'
            : 'bg-white text-neutral'} border-r
            rounded-r"
    >
        {$_("card")}
    </button>
</div>

<style>
    .item {
        border-top-width: 1px;
        border-bottom-width: 1px;
        padding: 0.75rem;
        text-align: center;
        cursor: pointer;
        user-select: none;
    }
</style>
