import "./main.css";
import App from "./App.svelte";
import { getBrowserLocale, locale, translations } from "svelte-intl";
import { en, sv, fi } from "./l10n/index";
import { reset } from "./stores";
import { stateStore } from "./stores";
import utils from "./utils";
import * as Sentry from "@sentry/browser";
import { Settings } from "luxon";
console.info(utils.appVersion());
// ! Critical code
// The default timezone  should be Europe/Helsinki
Settings.defaultLocale = "fi-FI";
Settings.defaultZoneName = "Europe/Helsinki";
// Set up Sentry
if (process.env.NODE_ENV === "production") {
    try {
        Sentry.init({
            dsn: process.env.SENTRY_DSN,
            release: utils.appVersion(),
        });
        stateStore.update((val) => {
            return Object.assign(Object.assign({}, val), { sentryLoaded: true });
        });
    }
    catch (error) {
        console.error("Error loading Sentry: ", error);
    }
}
translations.update({ en, sv, fi });
// Get the customers language before clearing out localStorage
const lang = localStorage.getItem("lang");
// Clear local storage if new app version
const v = process.env.APP_VERSION;
if (localStorage.getItem("version") !== v) {
    reset();
    localStorage.setItem("version", v);
}
if (lang) {
    locale.set(lang);
}
else {
    const _default = getBrowserLocale("sv");
    localStorage.setItem("lang", _default);
    locale.set(_default);
}
const app = new App({
    target: document.body,
});
export default app;
