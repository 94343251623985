<script lang="ts">
    import { locale, _ } from "svelte-intl";

    import CloseModal from "./CloseModal.svelte";
</script>

<div>
    <div class="flex flex-row justify-between mb-def">
        <h1 class="mr-2">{$_("store_pickup")}</h1>
        <div class="h-auto mt-1">
            <CloseModal />
        </div>
    </div>

    <div class="space-y-12 text-sm">
        <div>{$_("store_pickup_blurb_long")}</div>

        <div class="flex flex-row flex-wrap gap-8">
            <div>
                <h6 class="label">{$_("address")}</h6>
                <span>{$_("minimossen_address")}</span>
            </div>

            <div>
                <h6 class="label">{$_("pickup_hours")}</h6>

                {#if $locale === "fi"}
                    <table>
                        <tr><th>Ma</th><td>11-15:00</td></tr>
                        <tr><th>Ti-To</th><td>10-17:30</td></tr>
                        <tr><th>Pe</th><td>10-17:00</td></tr>
                        <tr><th>La</th><td>11-15:00</td></tr>
                        <tr><th>Su</th><td>{$_("closed")}</td></tr>
                    </table>
                {:else if $locale === "en"}
                    <table>
                        <tr><th>Monday</th><td>11am - 3:00pm</td></tr>
                        <tr><th>Tues - Thurs</th><td>10am - 5:30pm</td></tr>
                        <tr><th>Friday</th><td>10am - 5:00pm</td></tr>
                        <tr><th>Saturday</th><td>11am - 3:00pm</td></tr>
                        <tr><th>Sunday</th><td>{$_("closed")}</td></tr>
                    </table>
                {:else}
                    <table>
                        <tr><th>Mån</th><td>11-15:00</td></tr>
                        <tr><th>Tis-Tors</th><td>10-17:30</td></tr>
                        <tr><th>Fre</th><td>10-17:00</td></tr>
                        <tr><th>Lör</th><td>11-15:00</td></tr>
                        <tr><th>Sön</th><td>{$_("closed")}</td></tr>
                    </table>
                {/if}
            </div>
        </div>

        <div class="m-auto">
            <iframe
                title="Kierrätysgalleria Minimossen"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7214.480152695931!2d21.599982!3d63.124349!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd60afa9b6e5b99fe!2s%C3%85terbrukgsgalleria%20Minimossen!5e0!3m2!1ssv!2sus!4v1638968726333!5m2!1ssv!2sus"
                width="100%"
                height="400"
                style="border:0;"
                loading="lazy"
            />
        </div>
    </div>
</div>

<style>
    th {
        font-weight: normal;
        text-align: left;
    }

    td {
        padding: 0 12px;
    }
</style>
