<script lang="ts">
    // @ts-check
    import { createEventDispatcher } from "svelte";
    import List from "./List.svelte";
    import type { Item as ItemType } from "./Item";
    import Spinner from "../Spinner.svelte";
    import Close from "../../svg/Close.svelte";

    export let container: HTMLDivElement | undefined = undefined;
    export let placeholder = "";
    export let text = "";
    export let items: ItemType[] = [];
    export let isSelected = false;
    export let isError = false;
    export let isLoading = false;

    let hasFocus = false;
    let listVisible = false;
    let hoverItemIndex: number = 0;

    const dispatch = createEventDispatcher();

    function handleClick() {
        if (!isSelected || items.length === 0) {
            listVisible = true;
        }
    }

    function handleTextEntered() {
        listVisible = true;
        dispatch("textEntered", text);
    }

    function handleItemSelected(event: { detail: ItemType }) {
        dispatch("itemSelected", event.detail);
        isSelected = true;
        text = event.detail.label;
        items = [];
    }

    function handleWindowClick(event: MouseEvent & { target: EventTarget | null }) {
        // Close the dropdown list when any part of the window *other than*
        // the input box is clicked
        //
        // Casting here is probably horrible, but it works
        if (!container?.contains(event.target as Node)) {
            listVisible = false;
        }
    }

    function handleClear() {
        dispatch("itemCleared");
        isSelected = false;
        text = "";
        items = [];
    }

    function closeList() {
        listVisible = false;
    }

    $: if (listVisible === false) {
        hoverItemIndex = 0;
    }

    $: if (text.length === 0) {
        items = [];
    }

    function onFocus() {
        hasFocus = true;
    }

    function onBlur() {
        hasFocus = false;
    }
</script>

<svelte:window on:click={handleWindowClick} />

<div class="relative flex flex-col bg-white" bind:this={container}>
    <div
        class="{hasFocus ? 'input-wrapper__focused' : ''} {isError && !hasFocus
            ? 'border-warning'
            : ''} flex bg-transparent w-full input-wrapper input-wrapper__style overflow-auto"
    >
        <input
            placeholder={placeholder}
            readonly={isSelected}
            class="truncate input input__size border-transparent bg-transparent"
            bind:value={text}
            on:focus={onFocus}
            on:blur={onBlur}
            on:click={handleClick}
            on:input={handleTextEntered}
        />

        {#if isSelected}
            <button on:click={handleClear} class="self-center p-3 cursor-pointer tweak-focus">
                <Close fill="var(--color-neutral)" height="16" width="16" />
            </button>
        {/if}

        {#if !isSelected && isLoading}
            <div class="self-center mr-2">
                <Spinner color="var(--color-brand-light)" />
            </div>
        {/if}

        {#if items.length > 0}
            <button
                on:click={handleClick}
                class="self-center p-3 cursor-pointer tweak-focus-offset"
            >
                <svg
                    id="lnr-chevron-down"
                    fill="var(--color-neutral)"
                    viewBox="0 0 1024 1024"
                    height="16"
                    width="16"
                >
                    <path
                        d="M0 307.2c0-6.552 2.499-13.102 7.499-18.101
                        9.997-9.998 26.206-9.998 36.203 0l442.698 442.698
                        442.699-442.698c9.997-9.998 26.206-9.998 36.203 0s9.998
                        26.206 0 36.203l-460.8 460.8c-9.997 9.998-26.206
                        9.998-36.203
                        0l-460.8-460.8c-5-5-7.499-11.55-7.499-18.102z"
                    />
                </svg>
            </button>
        {/if}
    </div>

    {#if listVisible}
        <div class="relative">
            <div class="absolute z-10 w-full">
                <List
                    items={items}
                    bind:hoverItemIndex
                    on:itemSelected={handleItemSelected}
                    on:closeList={closeList}
                />
            </div>
        </div>
    {/if}
</div>

<style>
    .tweak-focus {
        outline-offset: -6px;
    }
</style>
