<script lang="ts">
    import type { Item } from "./Item";

    export let item: Item | undefined = undefined;
    export let isHover = false;
</script>

{#if item}
    <div class="{isHover ? 'bg-neutral-light' : ''} cursor-default leading-relaxed p-2
    truncate">
        {item.label}
    </div>
{/if}
