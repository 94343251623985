<script lang="ts">
    import utils from "../../../utils";
    import Tick from "../../../components/Tick.svelte";
    import type { ProductTypes } from "@edenflowers/server";

    export let card: ProductTypes.GetProductResBody[0];
</script>

<div class="relative overflow-hidden {card ? 'border border-primary' : ''}">
    <img src={utils.generate.imageURL(card.image, 400)} alt="Card" />

    <div class="absolute bottom-0 right-0 m-2 w-5 h-5">
        <Tick />
    </div>
</div>
