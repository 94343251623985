<script lang="ts">
    import type { ProductTypes } from "@edenflowers/server";
    import { getContext } from "svelte";
    import { locale, _ } from "svelte-intl";
    import CloseModal from "../../../components/CloseModal.svelte";
    import { addOrRemoveFromCart } from "../../../controllers/cart";
    import { extrasStore } from "../../../stores";
    import utils from "../../../utils";

    const { close } = getContext("simple-modal");

    export let product: ProductTypes.GetProductResBody[0];

    $: translation = product.translations.find((p) => p.language === $locale);
    $: added = $extrasStore.findIndex((store) => store.productId === product.id) !== -1;

    function handleAddToCart() {
        extrasStore.update((store) => {
            return addOrRemoveFromCart(store, product.id);
        });

        close();
    }
</script>

{#if product && translation}
    <div class="space-y-4">
        <div class="flex flex-row justify-between">
            <h1 class="mr-2">
                {translation.fullName}{product.weight ? ` ${product.weight}g` : ""}
            </h1>
            <div class="h-auto mt-1">
                <CloseModal />
            </div>
        </div>

        <button
            on:click={handleAddToCart}
            class="px-3 py-2 border bg-primary text-white font-semibold"
            >{added
                ? $_("remove", {
                      value: utils.localise.currency(product.cost, $locale),
                  })
                : $_("add", { value: utils.localise.currency(product.cost, $locale) })}
        </button>

        <div class="space-y-4 text-sm">
            {@html translation.description}
        </div>

        <img src={utils.generate.imageURL(product.image, 600)} alt={translation.name} />
    </div>
{/if}
