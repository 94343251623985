<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import { _, locale } from "svelte-intl";
    import Tick from "./Tick.svelte";
    import utils from "../utils";
    import type { ProductTypes } from "@edenflowers/server";

    const dispatch = createEventDispatcher();

    export let translations: ProductTypes.GetProductResBody[0]["translations"];
    export let image: ProductTypes.GetProductResBody[0]["image"];
    export let cost: ProductTypes.GetProductResBody[0]["cost"];
    export let selected = false;

    $: title = translations.find((t) => t.language === $locale)?.name || "";
</script>

<button on:click={() => dispatch("info")}>
    <div
        class="mb-1 cursor-pointer {selected ? 'border border-brand' : 'border border-transparent'}"
    >
        <div class="relative">
            <img class="shadow-lg" src={utils.generate.imageURL(image, 800)} alt={title} />

            {#if selected}
                <div class="absolute bottom-0 right-0 m-2 w-5 h-5">
                    <Tick />
                </div>
            {/if}
        </div>
    </div>

    <div class="flex flex-row items-center justify-between mb-1 text-sm">
        <span class="mr-1 truncate">{title}</span>
        <span class="font-medium">{utils.localise.currency(cost, $locale)}</span>
    </div>
</button>
