import { fetcher } from "@edenflowers/common";
import config from "../config";
import utils from "../utils";
export async function getDeliveryCalendar() {
    const res = await fetcher({
        url: `${config.serverUrl}/delivery/calendar`,
        method: "GET",
        callback: (err) => utils.sendErrorToSentry(err),
    });
    return res;
}
