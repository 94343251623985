<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import Label from "./forms/Label.svelte";
    import { _ } from "svelte-intl";

    const dispatch = createEventDispatcher();

    export let labelText = "";
    export let query = "";
    export let inputVisible = false;
    export let applied = false;
    let hasFocus = false;

    $: if (query || query.length === 0) {
        applied = false;
    }

    async function reset() {
        applied = false;
        query = "";

        dispatch("reset");
    }

    async function apply() {
        if (query.length > 0) {
            applied = true;

            dispatch("apply");
        }
    }
</script>

{#if !inputVisible}
    <button
        class="cursor-pointer hover:underline"
        on:click={() => (inputVisible = true)}
        tabindex="0"
        role="link"
    >
        {$_("use_promo_code")}
    </button>
{:else}
    <Label text={labelText} />

    {#if applied}
        <div class="mb-2">
            <slot />
        </div>
    {/if}

    <div
        class="flex bg-transparent w-full input-wrapper input-wrapper__style {hasFocus
            ? 'input-wrapper__focused'
            : ''}
        justify-between overflow-auto"
    >
        <input
            class="truncate w-full input input__size border-transparent bg-transparent"
            on:focus={() => (hasFocus = true)}
            on:blur={() => (hasFocus = false)}
            readonly={applied}
            bind:value={query}
            type="text"
        />
        <button
            class="px-4 tweak-focus flex-shrink-0"
            on:click={applied ? () => reset() : () => apply()}
        >
            {#if applied}{$_("clear")}{:else}{$_("apply")}{/if}
        </button>
    </div>
{/if}

<style>
    .tweak-focus {
        outline-offset: -5px;
    }
</style>
