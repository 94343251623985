<script lang="ts">
    import Tick from "../../../components/Tick.svelte";
    import utils from "../../../utils";
    import type { ProductTypes } from "@edenflowers/server";
    import { _, locale } from "svelte-intl";

    export let card: ProductTypes.GetProductResBody[0];
    export let isSelected = false;
</script>

<button id={card.id} on:click>
    <div
        class="mb-2 relative overflow-hidden shadow-lg {isSelected
            ? 'border border-primary'
            : 'border border-opacity-50'}"
    >
        <img
            class="h-full w-full cursor-pointer"
            src={utils.generate.imageURL(card.image, 400)}
            alt="card"
        />

        {#if isSelected}
            <div class="absolute bottom-0 right-0 m-2 w-5 h-5">
                <Tick />
            </div>
        {/if}
    </div>

    <div class="flex justify-between text-sm">
        <span class="truncate">{card.translations.find((t) => t.language === $locale)?.name}</span>
        <span class="font-medium">{utils.localise.currency(card.cost, $locale)}</span>
    </div>
</button>
