<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import { _ } from "svelte-intl";
    import Banner from "../../../components/Banner.svelte";
    import BinaryInput from "../../../components/BinaryInput.svelte";
    import api from "../../../api";
    import Tick from "../../../svg/Tick.svelte";
    import WarnTriangle from "../../../svg/WarnTriangle.svelte";

    const dispatch = createEventDispatcher();

    export let query = "";
    export let applied = false;

    let text = "";
    let valid = false;

    $: {
        text = "";
        if (valid) {
            text = $_("promo_valid");
        } else {
            text = $_("promo_invalid");
        }
    }

    async function apply() {
        const res = await api.checkPromo(query);
        if (res.err) {
            // TODO: handle error
            console.error(res);
            return;
        }

        // @ts-ignore
        valid = res.val.data?.valid || false;
        dispatch("apply");
    }
</script>

<BinaryInput labelText={$_("promo_code")} bind:applied bind:query on:reset on:apply={apply}>
    <Banner>
        <div class="flex leading-none items-center">
            <div class="h-4 w-4 flex-shrink-0 mr-2">
                {#if valid}
                    <Tick />
                {:else}
                    <WarnTriangle />
                {/if}
            </div>
            <p class="text-sm">{text}</p>
        </div>
    </Banner>
</BinaryInput>
