import { Err, Ok } from "ts-results";
import { getProducts } from "../../api/getProducts";
async function verifyProductId(id) {
    return getProducts({ category: ["primary"] }, id);
}
export function newCartItem(id, n = 1) {
    return {
        productId: id,
        quantity: n,
    };
}
// If the product is not found in the cart, return a new cart item
function checkCartForProduct(cart, product) {
    let found = false;
    for (let i = 0; i < cart.length; i++) {
        if (cart[i].productId === product.id) {
            found = true;
        }
    }
    if (!found) {
        return newCartItem(product.id);
    }
}
function addProductToCart(cart, products) {
    // Use spread operator to shallow copy cart
    const updatedCart = [...cart];
    for (let i = 0; i < products.length; i++) {
        const item = checkCartForProduct(cart, products[i]);
        if (item) {
            updatedCart.push(item);
        }
    }
    return updatedCart;
}
export var ErrorState;
(function (ErrorState) {
    ErrorState["INVALID_PRODUCT_ID"] = "INVALID_PRODUCT_ID";
})(ErrorState || (ErrorState = {}));
export async function validateIdAndUpdateCart(cart, id) {
    const res = await verifyProductId(id);
    if (res.err) {
        return new Err(res.val);
    }
    if (res.ok) {
        const updatedCart = addProductToCart(cart, res.val.data);
        return new Ok(updatedCart);
    }
}
function inCart(cart, id) {
    return cart.findIndex((n) => n.productId === id) !== -1;
}
export function findInCart(cart, id) {
    return cart.find((n) => n.productId === id);
}
function _addOneToCart(cart, item) {
    const c = [...cart];
    c.push(item);
    return c;
}
function _removeFromCart(cart, id) {
    return cart.filter((i) => i.productId !== id);
}
export function addOneToCart(cart, id, quantity = 1) {
    if (!inCart(cart, id)) {
        return _addOneToCart(cart, newCartItem(id, quantity));
    }
    console.error(`Error adding item - item ${id} exists in cart`);
    return cart;
}
export function addManyToCart(cart, add) {
    let c = [...cart];
    for (let i = 0; i < add.length; i++) {
        const { productId, quantity } = add[i];
        c = addOneToCart(c, productId, quantity);
    }
    return c;
}
export function removeFromCart(cart, id) {
    if (inCart(cart, id)) {
        return _removeFromCart(cart, id);
    }
    console.error(`Error removing item - item ${id} not in cart`);
    return cart;
}
export function addOrRemoveFromCart(cart, id) {
    if (inCart(cart, id)) {
        return _removeFromCart(cart, id);
    }
    return _addOneToCart(cart, newCartItem(id));
}
export function incrementItem(cart, id, n = 1) {
    if (!inCart(cart, id)) {
        console.error(`Error incrementing item - item ${id} not in cart`);
        return cart;
    }
    return cart.map((val) => {
        if (val.productId === id) {
            val.quantity += n;
        }
        return val;
    });
}
export function decrementItem(cart, id, n = 1) {
    if (!inCart(cart, id)) {
        console.error(`Error decrementing item - item ${id} not in cart`);
        return cart;
    }
    return cart.map((val) => {
        // Lowest number is 1
        if (val.productId === id && val.quantity > 1) {
            val.quantity -= n;
        }
        return val;
    });
}
function mergeTwoCarts(cartA, cartB) {
    const res = [...cartA];
    for (let i = 0; i < cartB.length; i++) {
        if (!inCart(res, cartB[i].productId)) {
            res.push(cartB[i]);
        }
    }
    return res;
}
export function mergeCarts(carts) {
    let res = [];
    for (let i = 0; i < carts.length; i++) {
        res = mergeTwoCarts(res, carts[i]);
    }
    return res;
}
