<script lang="ts">
    import { onMount } from "svelte";
    import { fly } from "svelte/transition";
    import { useNavigate } from "svelte-navigator";
    import { _, locale } from "svelte-intl";
    import {
        deliveriesStore,
        hashStore,
        stripeStore,
        orderStore,
        reset as localStoresReset,
        paymentStore,
    } from "../../../stores";
    import PaymentMethod from "./PaymentMethod.svelte";
    import Summary from "./Summary.svelte";
    import Button from "../../../components/Button.svelte";
    import api from "../../../api";
    import utils from "../../../utils";
    import Promo from "./Promo.svelte";
    import type { CartTypes, Language, PaymentMethod as IPaymentMethod } from "@edenflowers/server";
    import GiftCard from "./GiftCard.svelte";
    import { determinePaymentMethod, prepareDeliveries } from "../../../controllers/order";
    import { mergeCarts, newCartItem } from "../../../controllers/cart";
    import { loadStripe } from "@stripe/stripe-js/pure";
    import type { CheckoutLocale, Stripe, StripeElements } from "@stripe/stripe-js";
    import Field from "../../../components/forms/Field.svelte";
    import Banner from "../../../components/Banner.svelte";
    import CheckPhoneNumber from "../../../components/CheckPhoneNumber.svelte";

    export let navigate = useNavigate();

    let firstName = "";
    let lastName = "";
    let email = "";
    let phoneNumber = "";
    let promoCode = "";
    let promoApplied = false;
    let giftCardCode = "";
    let giftCardApplied = false;
    let paymentMethod: IPaymentMethod = "invoice";

    let cart: CartTypes.CalculateCartResBody | undefined;
    let stripe: Stripe | null;
    let stripeElements: StripeElements | null;
    let stripeErrorMsg: string = "";
    let stripeDisabled = false;
    let processingOrder = false;
    let contactName = "";
    let paymentMethodVisible = true;

    let showErrorMsg = false;
    let errMsg = "";
    $: if (!showErrorMsg) errMsg = "";
    let isError = false;
    let errors: { [k: string]: boolean } = {};
    $: isError = Object.keys(errors).length > 0;

    let firstSubmit = false;
    function validate() {
        if (firstSubmit) {
            errors = {};

            if (!firstName) errors.firstName = true;
            if (!lastName) errors.lastName = true;
            if (!email) errors.email = true;
            if (paymentMethod === "invoice" && !phoneNumber) errors.phoneNumber = true;
        }
    }

    // If the delivery first and last name are equal to the first and last
    // name of the payment information, we can assume that the phone number
    // will also be the same.
    let copiedPhoneNumber = false;
    function tryCopyPhoneNumber() {
        if (
            !phoneNumber &&
            !copiedPhoneNumber &&
            firstName &&
            lastName &&
            $deliveriesStore[0].firstName === firstName &&
            $deliveriesStore[0].lastName === lastName
        ) {
            copiedPhoneNumber = true;
            phoneNumber = $deliveriesStore[0].phoneNumber;
        }
    }

    async function calculateCart() {
        const calculateCartRes = await api.calculateCart({
            promoCode,
            giftCardCode,
            deliveries: $deliveriesStore.map((d) => {
                return {
                    hash: d.hash,
                    address: {
                        position: d.address.position,
                    },
                    items: mergeCarts([d.items, d.extras, [newCartItem(d.card)]]),
                };
            }),
        });

        if (calculateCartRes.err) {
            // TODO: handle error
            console.error(calculateCartRes);
            return;
        }

        // @ts-ignore
        cart = calculateCartRes.val.data;

        if (cart?.payable === 0 && (giftCardApplied || promoApplied)) {
            paymentMethodVisible = false;
            paymentMethod = "gift_card_or_promo";
        } else {
            paymentMethodVisible = true;
        }
    }

    onMount(async () => {
        if ($deliveriesStore.length === 0) {
            paymentStore.reset();
            navigate("/cart", { replace: true });
            return;
        }

        window.scrollTo(0, 0);

        if ($paymentStore !== null) {
            firstName = $paymentStore.firstName;
            lastName = $paymentStore.lastName;
            email = $paymentStore.email;
            phoneNumber = $paymentStore.phoneNumber;
        }

        if ($paymentStore === null && $deliveriesStore[0].deliveryMethod === "storePickup") {
            firstName = $deliveriesStore[0].firstName;
            lastName = $deliveriesStore[0].lastName;
            phoneNumber = $deliveriesStore[0].phoneNumber;
        }

        updateContactName();

        await calculateCart();
    });

    function createCardElement() {
        if (!stripe) {
            throw new Error("Error creating card element: Stripe not loaded");
        }

        stripeElements = stripe.elements();

        const cardElement = stripeElements.create("card", {
            disabled: stripeDisabled,
            iconStyle: "default",
            style: {
                base: {
                    color: "#373d3f",
                    fontFamily: "monospace",
                    fontSmoothing: "antialiased",
                    fontSize: "16px",
                    fontWeight: "500",
                    "::placeholder": {
                        color: "#96a0a4",
                    },
                },
                invalid: {
                    color: "#e93449",
                    iconColor: "#e93449",
                },
            },
        });

        cardElement.on("blur", () => {
            validate();
        });

        cardElement.on("ready", () => {
            if (firstName && lastName && email) {
                cardElement.focus();
            }
        });

        cardElement.on("change", (event: any) => {
            if (event.complete) {
                stripeErrorMsg = "";
            }

            if (event.error) {
                stripeErrorMsg = event.error.message;
            }
        });
    }

    let stripeElementLocale: string | undefined = $locale;

    function initStripe(node: HTMLElement) {
        // Clear out any previous errors
        stripeErrorMsg = "";

        // Always reload Stripe when initStripe is called
        loadStripe(process.env.STRIPE_PUB_KEY as string, {
            locale: $locale as CheckoutLocale,
        }).then((res) => {
            stripe = res;

            // If Stripe has not been mounted yet
            if (!node?.hasChildNodes()) {
                createCardElement();
                const cardElement = stripeElements?.getElement("card");
                cardElement?.mount(node);
            }

            // If language has been updated since initStripe was previously called
            if (stripeElementLocale !== $locale) {
                // Remove all children
                node.innerHTML = "";

                // Recreate and remount Stripe cardElement
                createCardElement();
                const cardElement = stripeElements?.getElement("card");
                cardElement?.mount(node);
            }

            stripeElementLocale = $locale;
        });

        return {
            update() {},
            destroy() {},
        };
    }

    // Respond to locale changes and reinitiate Stripe
    $: if ($locale !== stripeElementLocale) {
        const node = document.getElementById("card-element");
        if (node) initStripe(node);
    }

    // Prioritise submitting the order to the Eden Flowers server before
    // confirming the card payment with Stripe.
    async function submitOrder() {
        firstSubmit = true;
        processingOrder = true;

        validate();

        if (Object.keys(errors).length > 0) {
            processingOrder = false;
            return;
        }

        // Remember to update the hash list if more fields are added
        // to the order form
        const hash = utils.generate.hash(
            JSON.stringify({
                deliveries: $deliveriesStore.map((d) => {
                    return {
                        firstName: d.firstName,
                        lastName: d.lastName,
                        hereId: d.address.hereId, // unique address id
                        date: d.date,
                        message: d.message,
                        instructions: d.instructions,
                        pickup: d.deliveryMethod === "storePickup",
                        items: mergeCarts([d.items, d.extras, [newCartItem(d.card)]]),
                    };
                }),
                language: $locale,
                firstName,
                lastName,
                email,
                phoneNumber,
                promoCode,
                paymentMethod,
            })
        );

        // If a card payment is declined for whatever reason and the customer resubmits
        // their order, the hash is used to evaluate if there have been any significant
        // changes to the order information. For example, if the delivery address was
        // updated then a new order would be placed.
        //
        // Scenario: (1) submit button pressed and order is received by server,
        // (2) card is declined by Stripe, (3) user updates order and (4) then
        // reattempts card payment and is successful. In this scnedario, a new
        // order is placed.
        //
        // TODO: mark first order as stale on server
        if ($hashStore !== hash) {
            const createOrderRes = await api.createOrder({
                paymentMethod: determinePaymentMethod(paymentMethod, cart?.payable || 0),
                promoCode,
                giftCardCode,
                customer: {
                    firstName: firstName.trim(),
                    lastName: lastName.trim(),
                    email: email.trim(),
                    phoneNumber,
                },
                language: $locale as Language,
                deliveries: prepareDeliveries($deliveriesStore),
            });

            if (createOrderRes.err) {
                // TODO: handle error
                errMsg =
                    "Sorry, your order could not be made due to a server error. Please email info@edenflowers.fi";
                showErrorMsg = true;
                processingOrder = false;
                console.error(createOrderRes);
                return;
            }

            const order = createOrderRes.val.data;

            // If the order is successful, the order id is added to localStorage
            // @ts-ignore
            orderStore.set(order.id);
        } else {
            console.log("No changes to order since previous submit attempt");
        }

        // If the order was successful
        if ($orderStore) {
            hashStore.set(hash);

            // Continue with payment
            if (paymentMethod === "card") {
                let paymentIntentId;
                if ($stripeStore) {
                    paymentIntentId = $stripeStore;
                } else {
                    console.log("No paymentIntentId in local storage");
                }

                // Create or update the payment intent on the Eden Flowers server
                const initCardPaymentRes = await api.initCardPayment({
                    paymentMethod: "card",
                    orderId: $orderStore,
                    paymentIntentId,
                });

                if (initCardPaymentRes.err) {
                    // TODO: handle error
                    console.error(initCardPaymentRes);
                    return;
                }

                const initiatedPaymentData = initCardPaymentRes.val.data;

                // Store the intent from Eden Flowers server in localStorage
                // @ts-ignore
                stripeStore.set(initiatedPaymentData ? initiatedPaymentData.id : "");

                // Use the client secret to confrim the card payment with Stripe
                try {
                    const stripeResponse: any = await stripe?.confirmCardPayment(
                        // @ts-ignore
                        initiatedPaymentData.clientSecret,
                        {
                            payment_method: {
                                card: stripeElements?.getElement("card"),
                                billing_details: {
                                    name: `${firstName.trim()} ${lastName.trim()}`,
                                    email: email.trim(),
                                },
                            },
                        }
                    );

                    // If all ok, Stripe returns paymentIntent object
                    if (stripeResponse.error) {
                        stripeErrorMsg = stripeResponse.error.message;
                        processingOrder = false;
                        return;
                    }
                } catch (error) {
                    utils.sendErrorToSentry(error);
                    console.error("Error contacting Stripe server: ", error);
                    processingOrder = false;
                    return;
                }
            }

            navigate("/cart/success", { replace: true });

            // Clear cart state from local storage
            localStoresReset();
        }
    }

    function handleEditOrder(event: any) {
        navigate(`/cart/delivery?edit=${event.detail}`, { replace: false });
    }

    function handleRemoveOrder(event: any) {
        if (confirm($_("confirm_remove_delivery"))) {
            cart = undefined;

            deliveriesStore.set($deliveriesStore.filter((d) => d.hash !== event.detail));

            if ($deliveriesStore.length === 0) {
                paymentStore.reset();
                navigate("/cart", { replace: true });
                return;
            }

            calculateCart();
        }
    }

    function updateContactName() {
        contactName = firstName;
    }

    let prevEmail = "";
    let showEmailWarning = false;

    async function checkEmail() {
        showEmailWarning = false;

        if (email.length !== 0 && email !== prevEmail) {
            const res = await api.checkEmail(email);
            if (res.err) {
                // In the event there is a problem with the endpoint, hide error message
                showEmailWarning = false;
                return;
            }

            prevEmail = email;
            // @ts-ignore
            showEmailWarning = !res.val.data?.check || false;
        }
    }
</script>

<div class="order-layout">
    <div class="order-col">
        <section class="box">
            <Summary
                cart={cart}
                on:editOrder={handleEditOrder}
                on:removeOrder={handleRemoveOrder}
            />
        </section>
    </div>

    <div class="order-col">
        <section class="box">
            <h1 class="mb-def">{$_("payment_details")}</h1>
            <div class="flex mb-def">
                <div class="w-full flex-1 pr-2">
                    <Field
                        errors={errors}
                        name="firstName"
                        label={$_("first_name")}
                        bind:value={firstName}
                        on:blur={() => {
                            paymentStore.update((val) => {
                                return { ...val, firstName };
                            });
                            updateContactName();
                            tryCopyPhoneNumber();
                            validate();
                        }}
                    />
                </div>

                <div class="w-full flex-1">
                    <Field
                        errors={errors}
                        name="lastName"
                        label={$_("last_name")}
                        bind:value={lastName}
                        on:blur={() => {
                            paymentStore.update((val) => {
                                return { ...val, lastName };
                            });
                            tryCopyPhoneNumber();
                            validate();
                        }}
                    />
                </div>
            </div>

            <div class="mb-def">
                <Field
                    name="email"
                    label={$_("email_address")}
                    errors={errors}
                    bind:value={email}
                    on:blur={() => {
                        paymentStore.update((val) => {
                            return { ...val, email };
                        });
                        checkEmail();
                        validate();
                    }}
                >
                    <div slot="above-input">
                        {#if showEmailWarning}
                            <div class="mb-2">
                                <Banner>
                                    <div class="text-sm">{$_("check_email_address")}</div>
                                </Banner>
                            </div>
                        {/if}
                    </div>
                </Field>
            </div>

            <div class="mb-def">
                <CheckPhoneNumber
                    errors={errors}
                    contactName={contactName}
                    bind:number={phoneNumber}
                    on:blur={() => {
                        paymentStore.update((val) => {
                            return { ...val, phoneNumber };
                        });
                        validate();
                    }}
                />
            </div>
        </section>

        <section class="box">
            <h1 class="mb-def">{$_("payment_method")}</h1>

            {#if paymentMethodVisible}
                <div class="mb-def">
                    <PaymentMethod bind:selected={paymentMethod} />
                </div>
            {/if}

            <div class="mb-def">
                <GiftCard
                    remainingBalance={cart?.giftCardBalanceRemaining || 0}
                    bind:query={giftCardCode}
                    bind:applied={giftCardApplied}
                    on:apply={() => calculateCart()}
                    on:reset={() => calculateCart()}
                />
            </div>

            <div class="mb-def">
                <Promo
                    bind:query={promoCode}
                    bind:applied={promoApplied}
                    on:apply={() => calculateCart()}
                    on:reset={() => calculateCart()}
                />
            </div>
        </section>

        <section>
            {#if showErrorMsg}
                <div class="mb-2">
                    <Banner><span class="text-sm">{errMsg}</span></Banner>
                </div>
            {/if}

            {#if paymentMethod === "card"}
                <div
                    class="border-t border-r border-l rounded-t p-5 bg-white stripe-div-height{errors[
                        'stripe'
                    ]
                        ? 'border-red-400'
                        : ''}"
                >
                    {#if stripeErrorMsg}
                        <div class="text-sm mb-4">{stripeErrorMsg}</div>
                    {/if}

                    <div use:initStripe id="card-element" />
                </div>
            {/if}

            {#if cart}
                <Button
                    style="self-center w-full p-3 font-bold text-white shadow-sm
                        {paymentMethod ===
                    'card'
                        ? 'rounded-b'
                        : 'rounded'}
                        {processingOrder
                        ? 'bg-primary opacity-50'
                        : 'bg-primary'}"
                    isProcessing={processingOrder}
                    on:click={submitOrder}
                >
                    {#if isError}
                        <div in:fly={{ y: -6, duration: 250 }}>{$_("review_payment_details")}</div>
                    {:else}
                        <div in:fly={{ y: -6, duration: 250 }}>
                            <span>
                                {$_("pay")}
                                {utils.localise.currency(cart?.payable || 0, $locale)}
                            </span>
                        </div>
                    {/if}
                </Button>
            {/if}
        </section>
    </div>
</div>

<style>
    .stripe-div-height {
        min-height: 62px;
    }
</style>
