<script lang="ts">
    import "focus-visible"; // CSS polyfill for focus-visible
    import { Router, Route } from "svelte-navigator";
    import Cart from "./pages/cart/Index.svelte";
    import Payment from "./pages/cart/payment/Index.svelte";
    import Delivery from "./pages/cart/delivery/Index.svelte";
    import Success from "./pages/cart/success/Index.svelte";
    import NotFound from "./pages/NotFound.svelte";
    import Modal from "./components/Modal.svelte";
    import Footer from "./components/Footer.svelte";
    import Header from "./components/Header.svelte";
    import { locale } from "svelte-intl";
    import { productStore, calendarStore } from "./stores";
    import { onMount } from "svelte";
    import api from "./api";
    import DeliveryAlert from "./components/DeliveryAlert.svelte";

    export let url = "";
    // @ts-ignore
    let modalOpen = false;

    onMount(async () => {
        // Load product catalogue
        const getProductsRes = await api.getProducts({ category: ["primary", "secondary"] });
        if (getProductsRes.err) {
            // TODO: handle error
            console.error(getProductsRes);
            return;
        }

        if (getProductsRes.ok) {
            productStore.set(getProductsRes.val.data || []);
        }

        // Load delivery calendar dates
        const getCalendarDatesRes = await api.getDeliveryCalendar();
        if (getCalendarDatesRes.err) {
            // TODO: handle error
            console.error(getCalendarDatesRes);
            return;
        }

        if (getCalendarDatesRes.ok) {
            calendarStore.set(getCalendarDatesRes.val.data);
        }
    });

    $: ready = $productStore.length > 0 && $calendarStore;

    $: if ($locale) {
        localStorage.setItem("lang", $locale);
    }

    let scrollTop = 0;
    let scrollLeft = 0;

    function disableScroll() {
        scrollTop = document.documentElement.scrollTop;
        scrollLeft = document.documentElement.scrollLeft;

        window.onscroll = () => {
            window.scrollTo(scrollLeft, scrollTop);
        };
    }

    function enableScroll() {
        window.onscroll = () => {};
    }
</script>

<Router url={url}>
    <Modal
        on:opened={() => {
            disableScroll();
            modalOpen = true;
        }}
        on:closed={() => {
            enableScroll();
            modalOpen = false;
        }}
    >
        <body>
            {#if $calendarStore}
                <DeliveryAlert />
            {/if}

            <Header />

            <main class="mt-4 mb-16 p-2 flex flex-col flex-grow flex-shrink-0">
                {#if ready}
                    <Route path="cart/*">
                        <Route path="/">
                            <Cart />
                        </Route>
                        <Route path="/delivery">
                            <Delivery />
                        </Route>
                        <Route path="/payment">
                            <Payment />
                        </Route>
                        <Route path="/success">
                            <Success />
                        </Route>
                    </Route>

                    <Route component={NotFound} />
                {/if}
            </main>

            <Footer />
        </body>
    </Modal>
</Router>
