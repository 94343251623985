import * as Sentry from "@sentry/browser";
export default {
    sendErrorToSentry: (err) => {
        if (process.env.NODE_ENV === "production") {
            Sentry.captureException(err);
        }
    },
    isProduction: process.env.NODE_ENV === "production",
    appVersion: () => `${process.env.APP_NAME}@${process.env.APP_VERSION}`,
    generate: {
        // Simple hash function.
        // https://gist.github.com/iperelivskiy/4110988
        hash: (s) => {
            // eslint-disable-next-line no-var
            var a = 1, c = 0, h, o;
            if (s) {
                a = 0;
                /* jshint plusplus:false bitwise:false */
                for (h = s.length - 1; h >= 0; h--) {
                    o = s.charCodeAt(h);
                    a = ((a << 6) & 268435455) + o + (o << 14);
                    c = a & 266338304;
                    a = c !== 0 ? a ^ (c >> 21) : a;
                }
            }
            return String(a);
        },
        shortId: (length) => {
            const CHARS = "23456789abdegjkmnpqrvwxyz";
            const ID_LENGTH = length || 8;
            let rtn = "";
            for (let i = 0; i < ID_LENGTH; i++) {
                rtn += CHARS.charAt(Math.floor(Math.random() * CHARS.length));
            }
            return rtn;
        },
        imageURL(key, height) {
            return `${process.env.IMAGES_URL}/h_${height},f_auto,q_auto/${key}`;
        },
    },
    calculate: {
        epochSeconds: (millis) => {
            return Math.round(millis / 1000);
        },
        epochMillis: (seconds) => {
            return seconds * 1000;
        },
    },
    localise: {
        currency: (value, locale, showDecimal = false) => {
            const _value = value / 100;
            if (!showDecimal && _value % 1 == 0) {
                return new Intl.NumberFormat(locale, {
                    style: "currency",
                    currency: "EUR",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                }).format(_value);
            }
            return new Intl.NumberFormat(locale, {
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 2,
            }).format(_value);
        },
    },
    convert: {
        toEpochSeconds: (date) => {
            return Math.round(date.getTime() / 1000);
        },
    },
};
