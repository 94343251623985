<script lang="ts">
    import { getContext } from "svelte";
    import CardGroup from "./CardGroup.svelte";
    import { cardStore } from "../../../stores";
    import { _, locale } from "svelte-intl";
    const { close } = getContext("simple-modal");
    import utils from "../../../utils";
    import type { ProductTypes } from "@edenflowers/server";

    export let cards: ProductTypes.GetProductResBody = [];

    type Size = "small" | "medium" | "large";

    function getCardsBySize(cards: ProductTypes.GetProductResBody, size: Size) {
        return cards.filter((c) => c.size === size);
    }

    $: small = getCardsBySize(cards, "small");
    $: medium = getCardsBySize(cards, "medium");
    $: large = getCardsBySize(cards, "large");

    function handleClick(event: any) {
        cardStore.set(event.detail);
        close();
    }
</script>

<div class="max-w-4xl m-auto">
    {#if large.length > 0}
        <CardGroup
            on:click={handleClick}
            selected={$cardStore}
            heading={$_("large")}
            cards={large}
            showCloseModalButton={true}
        />
    {/if}

    {#if medium.length > 0}
        <CardGroup
            on:click={handleClick}
            selected={$cardStore}
            heading={$_("medium")}
            cards={medium}
            showCloseModalButton={large.length === 0}
        />
    {/if}

    {#if small.length > 0}
        <CardGroup
            on:click={handleClick}
            selected={$cardStore}
            heading={$_("small")}
            cards={small}
            showCloseModalButton={medium.length === 0}
        />
    {/if}
</div>
