import { writable } from "svelte/store";
export const store = (initial) => {
    const { set, subscribe, update } = writable(initial);
    return {
        reset: () => set(initial),
        subscribe,
        set: (value) => {
            return set(value);
        },
        update,
    };
};
