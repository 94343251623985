import { fetcher } from "@edenflowers/common";
import config from "../config";
import utils from "../utils";
export async function checkGiftCard(code) {
    const res = await fetcher({
        method: "GET",
        url: `${config.serverUrl}/giftcard/${code}`,
        callback: (err) => utils.sendErrorToSentry(err),
    });
    return res;
}
