<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import { _ } from "svelte-intl";
    import api from "../api";
    import Banner from "./Banner.svelte";
    import Field from "./forms/Field.svelte";

    const dispatch = createEventDispatcher();

    export let showErrorMsg = false;
    export let contactName = "";
    export let number = "";
    export let errors: { [key: string]: boolean } = {};

    let prevNumber = "";

    async function checkPhoneNumber() {
        if (number.length > 0 && prevNumber !== number) {
            const res = await api.checkPhoneNumber(number);
            if (res.err) {
                showErrorMsg = false;
                return;
            }

            const { data } = res.val;

            showErrorMsg = !data?.check;

            // If the number is valid, update value with formatted value
            if (data?.check && data?.formatted) {
                number = data?.formatted;
            }
        }

        prevNumber = number;
    }

    $: if (number.length === 0) {
        showErrorMsg = false;
    }
</script>

<Field
    inputMode="tel"
    name="phoneNumber"
    errors={errors}
    label={contactName.length > 0
        ? $_("phone_number_with_name", {
              name: contactName,
          })
        : $_("phone_number")}
    bind:value={number}
    on:blur={() => {
        checkPhoneNumber();
        dispatch("blur");
    }}
>
    <div slot="above-input">
        {#if showErrorMsg}
            <div class="mb-2">
                <Banner>
                    <div class="text-sm">{$_("phone_number_check")}</div>
                </Banner>
            </div>
        {/if}
    </div>
</Field>
