<script lang="ts">
    import { useNavigate } from "svelte-navigator";
    import { getContext, onMount } from "svelte";
    import { fly } from "svelte/transition";
    import { _, locale } from "svelte-intl";
    import {
        deliveriesStore,
        cardStore,
        productStore,
        calendarStore,
        extrasStore,
    } from "../../../stores";
    import utils from "../../../utils";
    import Message from "./Message.svelte";
    import Cart from "./Cart.svelte";
    import Button from "../../../components/Button.svelte";
    import Field from "../../../components/forms/Field.svelte";
    import Label from "../../../components/forms/Label.svelte";
    import Banner from "../../../components/Banner.svelte";
    import AddressSearch from "../../../components/AddressSearch.svelte";
    import Pin from "../../../svg/Pin.svelte";
    import type { Address, Delivery, Item } from "../../../types";
    import type { DeliveryTypes } from "@edenflowers/server";
    import { ErrorState, validateIdAndUpdateCart } from "../../../controllers/cart";
    import api from "../../../api";
    import { getDeliveryWindowStart } from "../../../controllers/calendar";
    import Extras from "./Extras.svelte";
    import CheckPhoneNumber from "../../../components/CheckPhoneNumber.svelte";
    import flatpickr from "flatpickr";
    import { Swedish } from "flatpickr/dist/l10n/sv.js";
    import { Finnish } from "flatpickr/dist/l10n/fi.js";
    import { DateTime } from "luxon";
    import type { Instance } from "flatpickr/dist/types/instance";
    import { config } from "@edenflowers/common";
    import StorePickupModal from "../../../components/StorePickupModal.svelte";
    import { fullScreenOnMobile } from "../../../components/modalOptions";
    import DeliveryPicker from "./DeliveryPicker.svelte";

    export let navigate = useNavigate();

    let items: Item[] = [];
    let firstName = "";
    let lastName = "";
    let instructions = "";
    let phoneNumber = "";
    let addressLabel = "";
    let message = "";
    let address: Address | undefined;
    let date: string;
    let hash: string = "";

    let addressSelected = false;
    let buttonDisabled = false;
    let contactName = "";
    let messageError = false;
    let errors: { [k: string]: boolean } = {};
    let isError = false;
    $: isError = Object.keys(errors).length > 0;

    let deliveryMethod: Delivery["deliveryMethod"] = "storePickup";
    $: pickup = deliveryMethod === "storePickup";
    $: homeDelivery = deliveryMethod === "homeDelivery";

    let deliveryCheck: DeliveryTypes.CalculateDeliveryCostByPosResBody | undefined = undefined;

    // The first validaton occurs when the submit button is pressed
    let firstSubmit = false;
    function validate() {
        if (firstSubmit) {
            errors = {};
            if (!firstName) errors.firstName = true;
            if (!lastName) errors.lastName = true;
            if (!address && homeDelivery) errors.address = true;
            if (address && homeDelivery && deliveryCheck && !deliveryCheck.deliver)
                errors.address = true;
            if (!phoneNumber && pickup) errors.phoneNumber = true;
            if (!date) errors.date = true;
            if (messageError) errors.message = true;
        }
    }

    $: if (messageError || !messageError) {
        validate();
    }

    // Used for phone number label
    function updateContactName() {
        contactName = firstName;
    }

    async function handleAddressSelected(selected: Address) {
        address = selected;

        const calculateDeliveryRes = await api.calculateDeliveryCostByPos(address.position);

        if (calculateDeliveryRes.err) {
            console.error(calculateDeliveryRes.val);
            return;
        }

        // @ts-ignore
        deliveryCheck = calculateDeliveryRes.val.data;
        validate();
    }

    let defaultCardId: string;

    onMount(async () => {
        const params = new URLSearchParams(window.location.search);

        if (params.has("lang")) {
            const l = params.get("lang");

            if (l === "sv" || l === "en" || l === "fi") {
                locale.set(l);
            }
        }

        // We are making a safe assumption that a matching card will always be found in the database :)
        const cards = $productStore.filter((p) => p.type === "card" && p.cost <= 100);
        // Get a random card
        defaultCardId = cards[Math.floor(Math.random() * cards.length)].id as string;

        // If order exists, load order. For the time being we are assuming
        // that a customer can only create one delivery (or suborder).
        if ($deliveriesStore.length > 0) {
            navigate(`/cart/delivery?edit=${$deliveriesStore[0].hash}`, { replace: true });

            const d = $deliveriesStore[0];

            hash = d.hash;
            items = d.items;
            firstName = d.firstName;
            lastName = d.lastName;
            phoneNumber = d.phoneNumber;
            message = d.message;
            date = d.date;
            fp.setDate(date);

            deliveryMethod = d.deliveryMethod;
            if (d.deliveryMethod === "storePickup") {
                addressLabel = "";
                instructions = "";
                addressSelected = false;
            } else {
                addressLabel = d.address.label;
                instructions = d.instructions;
                addressSelected = true;
                handleAddressSelected(d.address);
            }

            updateContactName();

            // Use the default card if no card has been selected
            cardStore.set(d.card ? d.card : defaultCardId);
        }

        if (params.has("id")) {
            cardStore.set(defaultCardId);

            const id = params.get("id") as string;

            const res = await validateIdAndUpdateCart(items, id.split(","));
            if (res.err) {
                // A search query ("?id=1234") which doesn't return a product
                // @ts-ignore
                if (res.val === ErrorState.INVALID_PRODUCT_ID) {
                    return navigate(`/not-found?id=${params.get("id")}`, {
                        replace: true,
                    });
                }

                return console.error(res);
            }

            // @ts-ignore
            items = res.val;
        }

        if (items.length === 0) {
            return navigate(`/cart`, { replace: true });
        }
    });

    function handleSubmit() {
        firstSubmit = true;

        validate();

        if (!buttonDisabled && Object.keys(errors).length === 0) {
            buttonDisabled = true;

            const delivery: Delivery = {
                items,
                extras: $extrasStore,
                firstName: firstName.trim(),
                lastName: lastName.trim(),
                deliveryMethod: deliveryMethod,
                // Use the Minimossen address if the customer is picking up the order
                address: pickup ? config.delivery.minimossenAddress : address,
                instructions: instructions.trim(),
                phoneNumber: phoneNumber,
                date: date,
                message: message.trim(),
                created: DateTime.now().toISO(),
                // If the customer has not written a message AND has not chosen to pay
                // for a card, exclude the free card for the order
                card: message.trim().length === 0 && $cardStore === defaultCardId ? "" : $cardStore,
                hash: "",
            };

            // If editing an existing delivery
            if (hash) {
                // Remove order using hash
                deliveriesStore.set($deliveriesStore.filter((d) => d.hash !== hash));
            }

            // Add new/updated order to store
            deliveriesStore.update((store) => {
                return [
                    ...store,
                    {
                        ...delivery,
                        // Reuse hash from deliveriesStore or generate a new one
                        // Important for navigation using the back button!
                        // Hash should be a positve integer
                        hash: hash ? hash : utils.generate.hash(JSON.stringify(delivery)),
                    },
                ];
            });

            // Tidy up
            cardStore.set("");

            navigate("/cart/payment", { replace: false });
        }
    }

    function handleAddressCleared() {
        address = undefined;
        deliveryCheck = undefined;
        validate();
    }

    function handleDateSelected(dateStr) {
        // ! Critical code
        // dateStr provided by Flatpickr is in the format `YYYY-MM-DD`.
        // Use Luxon to convert into "proper" ISO using the Europe/Helsinki
        // as the zone. Delivery dates should always be captured in the same
        // zone as where the delivery will be made - for obvious reasons!
        date = DateTime.fromISO(dateStr).toISO();
        validate();
    }

    let fp: Instance;
    let flatpickrOpen = false;
    function loadFlatpickr(node) {
        fp = flatpickr(node, {
            wrap: true,
            altInput: true,
            altFormat: "d.m.Y",
            dateFormat: "Y-m-d",
            disableMobile: true,
            weekNumbers: false,
            locale: (() => {
                switch ($locale) {
                    case "sv":
                        return Swedish;
                    case "fi":
                        return Finnish;
                    default:
                        return {
                            firstDayOfWeek: 1,
                        };
                }
            })(),
            minDate: $calendarStore.start,
            maxDate: $calendarStore.end,
            disable: $calendarStore.dates.filter((x) => !x.deliver).map((x) => x.date),
            monthSelectorType: "static",
            onChange: (_selectedDates, dateStr, _instance) => {
                handleDateSelected(dateStr);
            },
            onOpen: () => {
                flatpickrOpen = true;
            },
            onClose: () => {
                flatpickrOpen = false;
            },
        });

        return {
            destroy() {
                fp.destroy();
            },
        };
    }

    // Respond to locale changes and reinitiate Flatpickr
    $: if ($locale) {
        const node = document.getElementById("flatpickr-element");
        if (node) loadFlatpickr(node);
    }

    const { open } = getContext("simple-modal");
    function showPickupModal() {
        open(StorePickupModal, {}, fullScreenOnMobile(window.innerWidth));
    }
</script>

<div class="order-layout">
    <div class="order-col">
        <section class="box">
            <Cart items={items} />
        </section>

        <section class="box">
            <h1 class="mb-def">{$_("delivery_details")}</h1>

            <div class="mb-def flex flex-col self-center">
                <DeliveryPicker bind:selected={deliveryMethod} />
            </div>

            <div class="mb-def">
                <Banner>
                    <p class="text-sm">
                        {#if pickup}
                            {$_("store_pickup_blurb")}
                            <span>
                                <button on:click={showPickupModal} class="font-bold text-brand"
                                    >{$_("more_info")}</button
                                >
                            </span>
                        {/if}

                        {#if homeDelivery}
                            {$_("delivery_blurb", {
                                distance: config.delivery.freeDeliveryDistM / 1000,
                            })}
                        {/if}
                    </p>
                </Banner>
            </div>

            <div class="flex mb-def">
                <div class="w-full flex-1 mr-1">
                    <Field
                        errors={errors}
                        name="firstName"
                        label={$_("first_name")}
                        bind:value={firstName}
                        on:blur={() => {
                            updateContactName();
                            validate();
                        }}
                    />
                </div>

                <div class="w-full flex-1">
                    <Field
                        errors={errors}
                        name="lastName"
                        label={$_("last_name")}
                        bind:value={lastName}
                        on:blur={validate}
                    />
                </div>
            </div>

            {#if homeDelivery}
                <div class="mb-def">
                    <Label text={$_("address")} />

                    {#if deliveryCheck}
                        <div class="mt-1 mb-2">
                            <Banner>
                                <div class="flex">
                                    <div class="h-4 w-4 mr-2 mt-1/2 flex-none">
                                        <Pin />
                                    </div>
                                    <p class="text-sm">{deliveryCheck.text[$locale]}</p>
                                </div>
                            </Banner>
                        </div>
                    {/if}

                    <AddressSearch
                        errors={errors}
                        placeholder={$_("delivery_address_placeholder")}
                        bind:isSelected={addressSelected}
                        bind:text={addressLabel}
                        on:itemSelected={(event) => handleAddressSelected(event.detail)}
                        on:itemCleared={handleAddressCleared}
                    />
                </div>

                <div class="mb-def">
                    <Field
                        name="deliveryInstructions"
                        placeholder={$_("delivery_instructions_placeholder", {
                            apartment: `${String.fromCharCode(
                                65 + Math.floor(Math.random() * 6)
                            )}${Math.floor(10 + Math.random() * 20)}`,
                            code: Math.floor(1000 + Math.random() * 9000),
                        })}
                        label={$_("delivery_instructions")}
                        bind:value={instructions}
                    />
                </div>
            {/if}

            <div class="mb-def">
                <CheckPhoneNumber
                    errors={errors}
                    contactName={contactName}
                    bind:number={phoneNumber}
                    on:blur={validate}
                />
            </div>

            <div class="mb-def">
                <Label text={$_("delivery_date")} />

                {#if date && homeDelivery}
                    <div class="mb-4">
                        <Banner>
                            <p class="text-sm">
                                {$_("delivery_time_info", {
                                    date: DateTime.fromISO(date)
                                        .setLocale($locale)
                                        .toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY),
                                    time: getDeliveryWindowStart(
                                        DateTime.fromISO(date),
                                        $calendarStore.dates
                                    )
                                        .setLocale($locale)
                                        .toLocaleString(DateTime.TIME_24_SIMPLE),
                                })}
                            </p>
                        </Banner>
                    </div>
                {/if}

                <div
                    id="flatpickr-element"
                    use:loadFlatpickr
                    class="
                        {errors['date']
                        ? 'border-warning'
                        : ''}
                        {flatpickrOpen
                        ? 'input-wrapper__focused'
                        : ''}
                        flatpickr overflow-hidden flex flex-row items-center
                        cursor-pointer input-wrapper input-wrapper__style"
                >
                    <div data-toggle />

                    <input class="input__size cursor-pointer" type="text" data-input />
                </div>
            </div>
        </section>
    </div>

    <div class="order-col">
        {#if $productStore.findIndex((p) => p.type === "extra") !== -1}
            <div class="box">
                <Extras products={$productStore.filter((p) => p.type === "extra")} />
            </div>
        {/if}

        <div class="box">
            <Message bind:text={message} bind:isError={messageError} errors={errors} />
        </div>

        <Button on:click={handleSubmit}>
            {#if isError}
                <div in:fly={{ y: -6, duration: 250 }}>{$_("review_delivery_details")}</div>
            {:else}
                <div in:fly={{ y: -6, duration: 250 }}>
                    {#if hash}{$_("update_delivery")}{:else}{$_("add_delivery_to_cart")}{/if}
                </div>
            {/if}
        </Button>
    </div>
</div>
