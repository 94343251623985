import { writable, get } from "svelte/store";
import { noop } from "svelte/internal";
export const localStore = (key, initial) => {
    const toString = (value) => JSON.stringify(value);
    const toObj = JSON.parse;
    if (localStorage.getItem(key) === null) {
        localStorage.setItem(key, toString(initial));
    }
    const saved = toObj(localStorage.getItem(key));
    const store = writable(saved);
    function set(new_value) {
        localStorage.setItem(key, JSON.stringify(new_value));
        store.set(new_value);
    }
    function update(fn) {
        set(fn(get(store)));
    }
    function subscribe(run, invalidate = noop) {
        return store.subscribe(run, invalidate);
    }
    return {
        reset: () => set(initial),
        subscribe,
        set,
        update,
    };
};
