<script>
    // @ts-check
    import utils from "../../../utils";
    import { _, locale } from "svelte-intl";

    export let text = "";
    export let total = 0;
    export let totalWithDiscount = 0;
    export let isDiscounted = false;
    export let useFree = false;
    export let bold = false;
</script>

<div class="flex justify-between {bold ? 'font-semibold' : ''}">
    <div>{text}</div>
    {#if isDiscounted && total !== 0}
        <div>
            <span class="opacity-50 line-through"> {utils.localise.currency(total, $locale)} </span>
            {utils.localise.currency(totalWithDiscount, $locale)}
        </div>
    {:else if useFree && total === 0}
        <div>{$_("free")}</div>
    {:else}
        <div>{utils.localise.currency(total, $locale)}</div>
    {/if}
</div>
