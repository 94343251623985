import { __rest } from "tslib";
import { mergeCarts, newCartItem } from "../cart";
export function prepareDeliveries(deliveries) {
    const arr = [];
    for (let i = 0; i < deliveries.length; i++) {
        const _a = deliveries[i], { items, extras, card, deliveryMethod } = _a, rest = __rest(_a, ["items", "extras", "card", "deliveryMethod"]);
        const carts = [items, extras];
        if (card)
            carts.push([newCartItem(card)]);
        arr.push(Object.assign(Object.assign({}, rest), { pickup: deliveryMethod === "storePickup", items: mergeCarts(carts) }));
    }
    return arr;
}
export function determinePaymentMethod(selected, payable) {
    if (payable === 0) {
        return "gift_card_or_promo";
    }
    else {
        return selected;
    }
}
