import { calculateCart } from "./calculateCart";
import { calculateDeliveryCostByPos } from "./calculateDeliveryCostByPos";
import { checkPromo } from "./checkPromo";
import { createOrder } from "./createOrder";
import { lookupAddress } from "./lookupAddress";
import { getProducts } from "./getProducts";
import { initCardPayment } from "./initCardPayment";
import { getDeliveryCalendar } from "./getDeliveryCalendar";
import { checkGiftCard } from "./checkGiftCard";
import { checkEmail } from "./checkEmail";
import { checkPhoneNumber } from "./checkPhoneNumber";
export default {
    calculateCart,
    calculateDeliveryCostByPos,
    checkPromo,
    createOrder,
    lookupAddress,
    getProducts,
    initCardPayment,
    getDeliveryCalendar,
    checkGiftCard,
    checkEmail,
    checkPhoneNumber,
};
