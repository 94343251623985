<script lang="ts">
    import { deliveriesStore } from "../../stores";
    import { useNavigate } from "svelte-navigator";
    import { onMount } from "svelte";
    import { _ } from "svelte-intl";
    import Asset37 from "../../svg/flowers/Asset37.svelte";

    const navigate = useNavigate();

    onMount(() => {
        if ($deliveriesStore.length > 0) {
            navigate("/cart/payment");
        }
    });
</script>

{#if $deliveriesStore.length === 0}
    <div class="wrapper flex flex-col">
        <h1 class="text-center">{$_("cart_empty")}</h1>

        <Asset37 />
    </div>
{/if}
