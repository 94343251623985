<script lang="ts">
    import { locale, _ } from "svelte-intl";
    import { calendarStore } from "../stores";
    import { getCalendarEntry } from "../controllers/calendar";
    import AlertBar from "./AlertBar.svelte";
    import { DateTime } from "luxon";

    $: entry = $calendarStore && getCalendarEntry(DateTime.now(), $calendarStore.dates);
</script>

{#if entry && entry.deliver}
    <AlertBar>
        <span class="text-sm"
            >{$_("reminder_order_before", {
                time: DateTime.fromISO(entry.orderWindowEnd)
                    .setLocale($locale)
                    .toLocaleString(DateTime.TIME_24_SIMPLE),
            })}</span
        >
    </AlertBar>
{/if}
