import config from "../config";
import { fetcher } from "@edenflowers/common";
import utils from "../utils";
export async function calculateDeliveryCostByPos(pos) {
    const res = await fetcher({
        method: "GET",
        url: `${config.serverUrl}/delivery/cost/pos/${pos}`,
        callback: (err) => utils.sendErrorToSentry(err),
    });
    return res;
}
