<script>
    import { locale } from "svelte-intl";

    export let id = "";

    const _locales = [
        {
            value: "fi",
            label: "🇫🇮 Suomeksi",
        },
        {
            value: "sv",
            label: "🇸🇪 Svenska",
        },
        {
            value: "en",
            label: "🇬🇧 English",
        },
    ];
</script>

<select id={id} class="p-3 select cursor-pointer border rounded border-brand" bind:value={$locale}>
    {#each _locales as { value, label }}
        <option class="option" value={value}>{label}</option>
    {/each}
</select>

<style>
    .select,
    .option {
        font-family: "Montserrat";
    }

    .select {
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        background-color: transparent;
    }

    .option {
        font: -moz-pull-down-menu;
    }
</style>
