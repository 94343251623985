<script lang="ts">
    import { locale } from "svelte-intl";
    import { productStore } from "../../../stores";
    import type { Item } from "../../../types";
    import utils from "../../../utils";

    export let item: Item;

    $: product = $productStore.find((product) => product.id === item.productId);
    $: translation = product?.translations.find((t) => t.language === $locale);
</script>

{#if product}
    <div class="flex flex-row">
        <img
            class="shadow-lg flex-shrink mr-4"
            src={utils.generate.imageURL(product.image, 80)}
            alt={translation?.name}
        />
        <div class="flex flex-col justify-center">
            <span class="text-xl leading-none mb-1 font-black font-serif text-primary"
                >{translation?.name}</span
            >
            <span class="text-lg leading-none"
                >{utils.localise.currency(product.cost, $locale)}</span
            >
        </div>
    </div>
{/if}
